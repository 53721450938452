import { Component, Input, OnInit, input } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { initCollapses, initDropdowns, initFlowbite } from 'flowbite';

@Component({
  selector: 'app-menu-option',
  templateUrl: './menu-option.component.html',
  styleUrls: ['./menu-option.component.scss'],
  standalone: true,
  imports: [RouterLink, RouterLinkActive],
})
export class MenuOptionComponent implements OnInit {
  @Input() id: string = '0';
  @Input() title: string = '';
  @Input() childs: any[] = [];
  @Input() route: string = '/';
  hidden: boolean = true;

  constructor() {}

  ngOnInit() {
    initFlowbite();
    initCollapses();
    initDropdowns();
  }
}
