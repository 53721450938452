<li class="flex items-center w-full h-full bg-white">
  <a
    [id]="'dropdownDividerButton_' + id"
    [attr.data-dropdown-toggle]="'dropdownDivider_' + id"
    [routerLink]="route"
    class="flex text-sm items-center justify-center w-full h-full font-semibold group md:hover:text-white md:p-0 md:hover:bg-primary pl-2 py-4 md:py-0"
    style="padding-left: 1rem !important; padding-right: 1rem !important"

  >
    <p class="flex w-full">{{ title }}</p>
    <svg
      class="w-[10px] ml-2 md:group-hover:hidden"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 10 6"
    >
      <path
        stroke="var(--ui-accent)"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="m1 1 4 4 4-4"
      />
    </svg>
    <svg
      class="w-[10px] ms-3 hidden md:group-hover:block"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 10 6"
    >
      <path
        stroke="var(--ui-secondary)"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="m1 1 4 4 4-4"
      />
    </svg>
  </a>

  <!-- Dropdown menu -->
  <div
    [id]="'dropdownDivider_' + id"
    class="hidden z-10 divide-y divide-gray-100 shadow w-44 text-accent md:text-white bg-white md:bg-[#D33A3A]"
  >
    <ul
      class="py-6 text-sm text-gray md:text-white space-y-1 bg-white md:bg-[#D33A3A]"
      [attr.aria-labelledby]="'dropdownDividerButton_' + id"
    >
      <!-- Aplicar modulo de rutas  -->

      @for (route of childs; track $index) {
      <li>
        <a
          [routerLink]="route.route"
          class="block px-6 text-accent md:text-white hover:text-black md:hover:text-white hover:font-bold"
        >
          {{ route.title }}
        </a>
      </li>
      }
    </ul>
  </div>
</li>
